@import '../../../sass/variables';

// SHAPES
.circle {
  border-radius: 0.3em;
}

.square {
  border-radius: 2px;
}

// TYPE
.bordered {
  &.inherit {
    border: 1px solid currentColor;
  }

  &.black {
    border: 1px solid $colorGray800;
  }

  &.blue {
    border: 1px solid $colorBlue700;
  }

  &.yellow {
    border: 1px solid $colorYellow200;
  }
}

.filled {
  &.inherit {
    background: currentColor;
  }

  &.black {
    background: $colorGray800;
  }

  &.blue {
    background: $colorBlue700;
  }

  &.yellow {
    background: $colorYellow200;
  }
}
